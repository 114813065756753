export const env = {
  DEV: {
    BACK_ENV: "dev",
    API_URL: `https://api.admin.nftydoor.com/dev`,
  },
  FRAN_CAJAL: {
    BACK_ENV: "dev-fran",
    API_URL: `https://api.admin.nftydoor.com/dev-fran`,
  },
  DANTE: {
    BACK_ENV: "dev-dante",
    API_URL: `https://api.admin.nftydoor.com/dev-dante`,
  },
  ROQUE: {
    BACK_ENV: "dev-roque",
    API_URL: `https://api.admin.nftydoor.com/dev-roque`,
  },
  LEO: {
    BACK_ENV: "dev-leo",
    API_URL: `https://api.admin.nftydoor.com/dev-leo`,
  },
  LU: {
    BACK_ENV: "dev-lu",
    API_URL: `https://api.admin.nftydoor.com/dev-lu`,
  },
  LUCHO: {
    BACK_ENV: "dev-lucho",
    API_URL: `https://api.admin.nftydoor.com/dev-lucho`,
  },
  JUSTO: {
    BACK_ENV: "dev-justo",
    API_URL: `https://api.admin.nftydoor.com/dev-justo`,
  },
  LEANDRO: {
    BACK_ENV: "dev-leandro",
    API_URL: `https://api.admin.nftydoor.com/dev-leandro`,
  },
  FRANCO: {
    BACK_ENV: "dev-franco",
    API_URL: `https://api.admin.nftydoor.com/dev-franco`,
  },
  BRUNO: {
    BACK_ENV: "dev-bruno",
    API_URL: `https://api.admin.nftydoor.com/dev-bruno`,
  },
  JOAQUIN: {
    BACK_ENV: "dev-joaquin",
    API_URL: `https://api.admin.nftydoor.com/dev-joaquin`,
  },
  NACHO: {
    BACK_ENV: "dev-nacho",
    API_URL: `https://api.admin.nftydoor.com/dev-nacho`,
  },
  GIOVANA: {
    BACK_ENV: "dev-giovana",
    API_URL: `https://api.admin.nftydoor.com/dev-giovana`,
  },
  WALDEMAR: {
    BACK_ENV: "dev-waldemar",
    API_URL: `https://api.admin.nftydoor.com/dev-waldemar`,
  },
  ALEJO: {
    BACK_ENV: "dev-alejo",
    API_URL: `https://api.admin.nftydoor.com/dev-alejo`,
  },
  SIMON: {
    BACK_ENV: "dev-simon",
    API_URL: `https://api.admin.nftydoor.com/dev-simon`,
  },
  NICOLAS: {
    BACK_ENV: "dev-nicolas",
    API_URL: `https://api.admin.nftydoor.com/dev-nicolas`,
  },
  ESTEBAN: {
    BACK_ENV: "dev-esteban",
    API_URL: `https://api.admin.nftydoor.com/dev-esteban`,
  },
  SHANE: {
    BACK_ENV: "dev-shane",
    API_URL: `https://api.admin.nftydoor.com/dev-shane`,
  },
  PABLOM: {
    BACK_ENV: "dev-pablom",
    API_URL: `https://api.admin.nftydoor.com/dev-pablom`,
  },
  JUANI: {
    BACK_ENV: "dev-juani",
    API_URL: `https://api.admin.nftydoor.com/dev-juani`,
  },
  PABLOBA: {
    BACK_ENV: "dev-pabloba",
    API_URL: `https://api.admin.nftydoor.com/dev-pabloba`,
  },
  JULIAN: {
    BACK_ENV: "dev-julian",
    API_URL: `https://api.admin.nftydoor.com/dev-julian`,
  },
  MARCOS: {
    BACK_ENV: "dev-marcos",
    API_URL: `https://api.admin.nftydoor.com/dev-marcos`,
  },
  MELANY: {
    BACK_ENV: "dev-melany",
    API_URL: `https://api.admin.nftydoor.com/dev-melany`,
  },
  MATIAS: {
    BACK_ENV: "dev-matias",
    API_URL: `https://api.admin.nftydoor.com/dev-matias`,
  },
  JULIO: {
    BACK_ENV: "dev-juliochort",
    API_URL: `https://api.admin.nftydoor.com/dev-juliochort`,
  },
  PROD: {
    BACK_ENV: "prod",
    API_URL: `https://api.admin.nftydoor.com/prod`,
  },
};

export const ENV = localStorage.getItem("currentEnvironment") ?? "DEV";

function getEnvVariable(key) {
  const isInProduction = process.env.REACT_APP_ENV === "prod";
  return env[isInProduction ? "PROD" : ENV][key];
}

export default getEnvVariable;
